import React from 'react'
import './Education.css'

const Education = () => {
  return (
    <div className='education-container'>
       <Item university="ISIM Monastir" diplome="Software Engineering Degree" duration="2023-2026"/>
       <Item university="ISIM Monastir" diplome="Software Pre-Engineering Program" duration="2021-2023"/>
       <Item university="Lycée Taib Mhiri Menzel Temim" diplome="High School Diploma" duration="2021"/>

    </div>
  )
}

export default Education;

const Item = ({university, diplome, duration}) => {
    return (
        <div className='eduction-item'>
            <h5>{diplome}</h5>
            <p>{university}</p>
            <span>{duration}</span>
            <div className='list-div'></div>
            <span className='list-span'></span>
        </div>
    )
}
