import React from 'react'
import './Resume.css'
import Education from './Education'
import Experience from './Experience'
const Resume = () => {
  return (
    <div id='resume' className='Resume'>
      <h2>My Resume</h2>
      <div className='bar'></div>
      <div className='Resume-container'>
        <div className='Education'>
            <h3>Education</h3>
            <Education/>
        </div>
        <div className='Experience'>
            <h3>Experience</h3>
            <Experience/>
        </div>
      </div>
    </div>
  )
}

export default Resume
