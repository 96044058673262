import React from 'react'
import './Education.css'

const Experience = () => {
  return (
    <div className='education-container'>
       <Item title="Summer internship" entreprise="Lamar Service" duration="06/2023 - 08/2023"/>
       <Item title="Volunteer Work" entreprise="CPU ISIMM" duration="2021-2024"/>

    </div>
  )
}

export default Experience;

const Item = ({title, entreprise, duration}) => {
    return (
        <div className='eduction-item'>
            <h5>{title}</h5>
            <p>{entreprise}</p>
            <span>{duration}</span>
            <div className='list-div'></div>
            <span className='list-span'></span>
        </div>
    )
}
