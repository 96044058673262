import React from 'react'
import './Footer.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope, faLocation } from '@fortawesome/free-solid-svg-icons'
import { faGithub, faLinkedinIn, faWhatsapp } from '@fortawesome/free-brands-svg-icons'
import image from '../../res/im1.png'

const Footer = () => {
  return (
    <div className='Footer'>
        <div className='footer-container'>
            <div className='footer-image'>
                <img src={image} alt='oussama ben slama'/>
                <span>Oussama Ben Slama</span>
            </div>
            <div className='footer-nav'>
                <ul>
                    <li><a href='#section'>Home</a></li>
                    <li><a href='#projects'>Projects</a></li>
                    <li><a href='#resume'>Resume</a></li>
                    <li><a>Contact</a></li>
                </ul>
            </div>
            <div className='footer-info'>
                <div>
                    <FontAwesomeIcon icon={faLocation} className='footer-icon'/> <span>Monstire, Tunisia</span>
                </div>
                <div>
                    <FontAwesomeIcon icon={faWhatsapp} className='footer-icon'/> <span>+216 28281153</span>
                </div>
                <div>
                    <FontAwesomeIcon icon={faEnvelope} className='footer-icon'/> <span>hello@ben-slama.tn</span>
                </div>
                <div>
                    <FontAwesomeIcon icon={faGithub} className='footer-icon'/> <span>OussamaBenSlama</span>
                </div>
                
            </div>
        </div>
        <p className='rights'>&copy; {new Date().getFullYear()} Oussama Ben Slama. All Rights Reserved.</p>
    </div>
  )
}

export default Footer