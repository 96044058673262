import React, { useState, useEffect } from 'react';
import './Right_section.css';
import im1 from '../../res/im1.jpg';
import im2 from '../../res/im2.jpg';
import im3 from '../../res/im3.jpg';
import im4 from '../../res/im4.jpg';
import im5 from '../../res/im5.jpg';
// import im6 from '../../res/im6.jpg';

const Right_section = () => {
  const images = [im1, im2, im3, im4, im5];
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 5000); // Switch images every 1 second

    return () => clearInterval(interval);
  }, [images.length]);

  return (
    <div className='Right'>
      <div className='cover-list'>
        {images.map((image, index) => (
          <img
            key={index}
            src={image}
            alt={`Image ${index + 1}`}
            style={{ display: index === currentImageIndex ? 'block' : 'none' }}
          />
        ))}
        
      </div>
    </div>
  );
};

export default Right_section;
