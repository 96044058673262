import React from 'react';
import './ProjectList.css';
import { projects } from './Projects';
import ProjectItem from './ProjectItem';

const ProjectList = () => {
  return (
    <div id='projects' className='ProjectList'>
      <h2>My Projects</h2>
      

      <div className='p-list'>
        {projects.map((item, index) => (
          <ProjectItem key={index} item={item}/>
        ))}
      </div>
      
    </div>
  );
}

export default ProjectList;
