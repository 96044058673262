import './App.css';
import Contact from './components/Contact/Contact';
import Footer from './components/Footer/Footer';
import Navbar from './components/Navbar/Navbar'
import ProjectList from './components/Projects/ProjectList';
import Resume from './components/Resume/Resume';
import Section from './components/Section/Section';
function App() {
  return (
    <div className="App">
      <Navbar/>
      <Section/>
      <ProjectList/>
      <Resume/>
      <Contact/>
      <Footer/>
      
    </div>
  );
}

export default App;
