import React from 'react'
import './Section.css'
import Left_section from './Left_section'
import Right_section from './Right_section'

const Section = () => {
  return (
    <div className='Section' id='section'>
      <Left_section/>
      <Right_section/>
    </div>
  )
}

export default Section
