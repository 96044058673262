import React, { useState, useEffect } from 'react';
import './Left_section.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faUser } from '@fortawesome/free-solid-svg-icons';
import { faGithub, faLinkedin } from '@fortawesome/free-brands-svg-icons';

const Left_section = () => {
  const [placeholder, setPlaceholder] = useState('');
  
  useEffect(() => {
    const temp = "Ouussama Ben Slama";
    let i = 0;
    const intervalId = setInterval(() => {
      if (i < temp.length) {
        setPlaceholder(prev => prev + temp.charAt(i));
        i++;
      } else {
        clearInterval(intervalId);
      }
    }, 100);
    return () => clearInterval(intervalId);
  }, []);

  return (
    <div className='Left'>
      <div className='user-info'>
        <div className='user'>
          <FontAwesomeIcon icon={faUser} className='icon'/>
        </div>
        <h2>Hi, I’m <span>{placeholder}</span></h2>
        <p>
          A software developer who loves making apps that people enjoy using
        </p>
        <ul>
          <li><FontAwesomeIcon icon={faEnvelope} className='info-icon'/><a href=''>hello@ben-slama.tn</a></li>
          <li><FontAwesomeIcon icon={faLinkedin} className='info-icon'/><a href='https://www.linkedin.com/in/oussama-ben-slama/'>Oussama Ben Slama</a></li>
          <li><FontAwesomeIcon icon={faGithub} className='info-icon'/><a href='https://github.com/OussamaBenSlama'>OussamaBenSlama</a></li>
        </ul>
      </div>
      <div className='user-cv'>
        <p>Download my curriculum vitae:</p>
        <button><a href='https://drive.google.com/drive/folders/1y1xzq4tuxBTXI7uHjxx3CMwBZQpYC6li?usp=sharing'>Download CV</a></button>
        <button><a href='#contact'>Contact me</a></button>
      </div>
    </div>
  )
}

export default Left_section;
