import mentorini1 from '../../res/mentorini.png'
import eventii from '../../res/eventii.png'
import cpu from '../../res/cpu.png'
import gluco from '../../res/gluco-check.png'
import qr from '../../res/qr-code.png'
import isimm from '../../res/isimm-website.png'

export const projects = [
    {
        image: mentorini1,
        name: "Mentorini-web",
        stack: "React js - Express js - mongo db - amazon s3",
        path : "https://www.mentorini.tn",
        description: "A platform designed to provide Tunisian students with valuable resources and facilitate the sharing of information"
    },
    {
        image: eventii,
        name: "Eventii",
        stack: "React js - Firebase",
        path : "https://www.eventii.tn",
        description: "A gateway to a plethora of events, workshops, seminars, and gatherings that are happening across Tunisian universities"
    },
    {
        image: cpu,
        name: "CPU-ISIMM",
        stack: "React js - node js - Firebase",
        path : "https://www.cpu-isimm.tn",
        description: "A website for our university club, serving as a showcase with integrated mail server for automatic registration confirmation"
    },
    {
        image: isimm,
        name: "ISIMM website",
        stack: "React js - Django",
        path : "https://github.com/OussamaBenSlama/IsimmWebsite",
        description: "An entire application designed to mirror the functionality of my university's website, encompassing all the essential features"
    },
    {
        image: gluco,
        name: "Gluco check",
        stack: "React js - Firebase",
        path : "https://github.com/OussamaBenSlama/gluco-check",
        description: "A web app for doctors to manage diabetic patients' data and view charts of diabetic ranges"
    },
    {
        image: qr,
        name: "Qr-code Generator",
        stack: "React js - Django",
        path : "https://github.com/OussamaBenSlama/QR-code-generator-react-django",
        description: "A QR code generator platform designed to provide users with a convenient tool to generate QR code"
    },
    
]