import React, {useEffect, useState} from 'react'
import './Navbar.css'

const Navbar = () => {
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
      const handleScroll = () => {
          const isScrolled = window.scrollY > 80;
          setScrolled(isScrolled);
      };

      window.addEventListener('scroll', handleScroll);

      return () => {
          window.removeEventListener('scroll', handleScroll);
      };
  }, []);

  
  return (
    <div className="header">
      <div className='Navbar'>
        <p className="logo" style={{cursor:'pointer'}}>Ben-Slama</p>
        <ul>
          <li><a href='#section'>Home</a></li>
          <li><a href='#projects'>Projects</a></li>
          <li><a href='#resume'>Resume</a></li>
          <li><a href='#contact'>Contact</a></li>
        </ul>
        
      </div>
      <div id="navBarWrapper" className={scrolled? "m-navBar-wrapper" : "m-navBar-wrapper transparent"} data-expanded="false">
        <div className="m-navBar">
            <div className="top">
                <a className="logo">Ben-Slama</a>
                <button onClick={()=> {
                  var e = document.getElementById('navBarWrapper');
                  e.setAttribute('data-expanded', e.getAttribute('data-expanded') == 'false');}
                  }>
                    <svg viewBox="0 0 10 10" width="100%" height="100%">
                        <path d="M2,2 L8,2 L8,5 L6,5 L2,5 L2,8 L8,8 L2,2 L8,2 L2,8" fill="none" stroke="black"/>
                    </svg>
                </button>
            </div>
            <div className="middle">
                <a href='#section'>Home</a>
                <a href='#projects'>Projects</a>
                <a href='#resume'>Resume</a>
                <a href='#contact'>Contact</a>
            </div>
            <span className="highlighter"></span>
        </div>
    </div>
  </div>
  )
}

export default Navbar