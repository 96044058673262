import React, { useEffect, useRef } from 'react';
import { motion, useAnimation } from 'framer-motion';
import './ProjectItem.css';

const ProjectItem = ({ item }) => {
  const ref = useRef(null);
  const controls = useAnimation();

  useEffect(() => {
    const handleScrollAnimation = () => {
      const element = ref.current;
      if (element) {
        const elementTop = element.offsetTop;
        const scrollPosition = window.scrollY + window.innerHeight;
        if (scrollPosition > elementTop) {
          controls.start({
            opacity: 1,
            y: 0,
            transition: { duration: 0.5 }
          });
        }
      }
    };

    window.addEventListener('scroll', handleScrollAnimation);
    return () => {
      window.removeEventListener('scroll', handleScrollAnimation);
    };
  }, [controls]);

  return (
    <motion.div
      ref={ref}
      className='ProjectItem'
      initial={{ opacity: 0, y: 50 }}
      animate={controls}
    >
      <div className='Project-header'>
        <img src={item.image} alt='' />
      </div>
      <div className='Project-decription'>
        <div className='Project-content'>
          <h3>{item.name}</h3>
          <p>{item.description}</p>
          <p id='stack'>{item.stack}</p>
        </div>
        <div className='Project-btn'>
          <button><a href={item.path}>Live Preview</a></button>
        </div>
      </div>
    </motion.div>
  );
};

export default ProjectItem;
